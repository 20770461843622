import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image } from 'app/components/Common/Image'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  image?: { [key: string]: any }
}

export const Visual = memo(function Visual({ cta, description, image }: Props) {
  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <>
          {image ? (
            <LazyLoad>
              <Background>
                <Image {...image} />
              </Background>
            </LazyLoad>
          ) : null}
          <Wrapper>
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta
              ? cta.map((item, index) => (
                  <CTA key={index} {...item} variant="corners" />
                ))
              : null}
          </Wrapper>
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem auto 6rem;
  position: relative;
  .react-reveal {
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto 3.75rem;
  }
`

const Background = styled.div`
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.div`
  max-width: 53.125rem;
  margin: auto;
  padding: 8.5rem 1.5rem;
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: 767px) {
    padding: 3.75rem 1.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 767px) {
    font-size: 1.375rem;
    line-height: 2.375rem;
  }
`

const CTA = styled(Button)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};

  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`
